import { education, employment } from "../data/career";

const About = () => {

  const renderEmployment = (res) => {
    return (
      <ul>
        <li className={'career-list-element'}>
          <b>{res.date}:</b> {res.experience}
        </li>
      </ul>
    );
  };

  const renderEducation = (res) => {
    return (
      <ul>
        <li className={'career-list-element'}>
          <b>{res.date}:</b>{' '}
          {`${res.experience}, Title: ${res.title}, Advisor: ${res.advisor}`}
        </li>
      </ul>
    );
  };

  return (
    <div>
      <h2>About</h2>
      <h3>Employment</h3>
      {employment.map(renderEmployment)}
      <h3>Education</h3>
      {education.map(renderEducation)}
    </div>
  );
};

export default About;
