import {
  IoMailOutline,
} from 'react-icons/io5';

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <IoMailOutline size={22} />
        </span>
        <span className={'contact-line-text'}>
          <a href={'emailto:piotr.surowka@pwr.edu.pl'}>
            piotr.surowka [at] pwr.edu.pl [at]
          </a>
        </span>
      </p>
      <p className={'contact-line'}>
        Wrocław University of Science and Technology
      </p>
      <p className={'contact-line'}>Wybrzeże Wyspiańskiego 27</p>
      <p className={'contact-line'}>50-370 Wrocław (Poland)</p>
    </div>
  );
};

export default Contact;
