export const employment = [
  {
    date: '2021',
    experience:
      'Associate Professor in Wrocław University of Science and Technology',
  },
  {
    date: '2016',
    experience:
      'Staff Scientist in the Max Planck Institute for the Physics of Complex Systems (MPI PKS), Dresden',
  },
  {
    date: '2016',
    experience:
      'Research associate in Max Planck Institute for Physics, Munich',
  },
  {
    date: '2013-2016',
    experience:
      'Postdoctoral Fellow at Harvard University',
  },
  {
    date: '2010-2013',
    experience:
      'Postdoctoral Research Associate at Vrije Universiteit Brussel (VUB) and The International Solvay Institutes',
  },
];

export const education = [
  {
    date: '2010',
    experience: 'Ph.D. Theoretical Physics, Jagiellonian University (JU)',
    title:
      'Non-perturbative aspects of supersymmetric plasma physics in a gauge theory',
    advisor: 'Romuald Janik',
  },
  {
    date: '2006',
    experience: 'M.Sc. Theoretical Physics, Jagiellonian University (JU)',
    title: 'Quark-antiquark interactions and the AdS/CFT correspondence',
    advisor: 'Romuald Janik',
  },
];