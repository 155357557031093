export const publications = [
  {
    title: 'Odd elasticity',
    link: 'https://www.nature.com/articles/s41567-020-0795-y',
    authors:
      'Colin Scheibner, Anton Souslov, Debarghya Banerjee, Piotr Surówka, William T. M. Irvine, Vincenzo Vitelli',
    publicatedIn: 'Nature Physics',
  },
  {
    title: 'Active viscoelasticity of odd materials',
    link: 'https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.126.138001',
    authors:
      'Debarghya Banerjee, Vincenzo Vitelli, Frank Jülicher, Piotr Surówka',
    publicatedIn: 'Phys. Rev. Lett.',
  },
  {
    title: 'Non-Abelian anomalies in multi-Weyl semimetals',
    link: 'https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.126.138001',
    authors:
      'Renato M. A. Dantas, Francisco Peña-Benitez, Bitan Roy, Piotr Surówka',
    publicatedIn: 'Phys. Rev. Research',
  },
  {
    title: 'Hydrodynamics from charged black branes',
    link: 'https://link.springer.com/article/10.1007/JHEP01%282011%29094',
    authors:
      'Nabamita Banerjee, Jyotirmoy Bhattacharya, Sayantani Bhattacharyya, Suvankar Dutta, R. Loganayagam, Piotr Surówka',
    publicatedIn: 'Journal of High Energy Physics',
  },
  {
    title: 'Hydrodynamics with Triangle Anomalies',
    link: 'https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.103.191601',
    authors: 'Dam T. Son, Piotr Surówka',
    publicatedIn: 'Phys. Rev. Lett. ',
  },
];
