import { publications } from '../data/publications';
import { IoDocumentTextOutline } from "react-icons/io5";
import '../styles/main.scss';

const Publications = () => {
  const renderAuthors = (authors) => {
    const indexMainAuthor = authors.search('Piotr Surówka');
    const firstPart = authors.substr(0, indexMainAuthor);
    const secondPart = authors.substr(indexMainAuthor + 13, authors.length);
    return (
      <p className={'publication-authors'}>
        {`${firstPart}`}
        <span className={'publication-piotr'}>{'Piotr Surówka'}</span>
        {`${secondPart}`}
      </p>
    );
  };

  const renderPublication = (pub) => {
    return (
      <li className={'publication-list-element'}>
        <a href={pub.link} target={'__blank'} className={'publication-link'}>
          <p>
            <b>{pub.title}</b>
          </p>
        </a>
        {renderAuthors(pub.authors)}
        <p className={'publication-publicated'}>{pub.publicatedIn}</p>
      </li>
    );
  };

  return (
    <div>
      <h2 className={'publication-title'}>Publications / Highlights</h2>
      <ul>{publications.map(renderPublication)}</ul>
      <p style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>
          <IoDocumentTextOutline />
        </span>
        <span style={{ marginRight: '5px' }}>Full list of </span>
        <a
          href={'https://scholar.google.com/citations?user=iv2yY8EAAAAJ&hl=en'}
          target="__blank"
          className="publication-general"
        >
          <b>{' publications'}</b>
        </a>
      </p>
    </div>
  );
};

export default Publications;
