const Members = () => {
  return (
    <div>
      <h2>Group Members</h2>
      <ul>
        <li className={'member-list-element'}>
          <b className={'member-name-element'}>Piotr Surówka</b>{' '}
          <span className={'member-role-element'}>Group leader</span>
        </li>
        <br />
        <li className={'member-list-element'}>
          <b className={'member-name-element'}>Aleksander Głódkowski</b>{' '}
          <span className={'member-role-element'}>PhD student</span>
        </li>
        <li className={'member-list-element'}>
          <b className={'member-name-element'}>Ruben Lier</b>{' '}
          <span className={'member-role-element'}>PhD student</span>
        </li>
        <li className={'member-list-element'}>
          <b className={'member-name-element'}>Paweł Matus</b>{' '}
          <span className={'member-role-element'}>PhD student</span>
        </li>
        <li className={'member-list-element'}>
          <b className={'member-name-element'}>Lazaros Tsaloukidis</b>{' '}
          <span className={'member-role-element'}>PhD student</span>
        </li>
      </ul>
    </div>
  );
};

export default Members;
