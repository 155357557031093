
import { useEffect, useRef } from 'react';
import "../styles/main.scss";

function Header(props) {
  const headerRef = useRef(null);

  const setHeaderMobile = () => {
    if (window.innerWidth < 769 && headerRef.current) {
      if (props.headerState) {
        headerRef.current.className = "header header__up";
        props.setBurgerMenu(true);
      }
    } else {
      props.setBurgerMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setHeaderMobile);
    return () => {
      window.removeEventListener("resize", setHeaderMobile);
    }
  });

  useEffect(() => {
    if (props.headerState) {
      headerRef.current.className = "header header__down";
    } else {
      headerRef.current.className = "header header__up";
    }
  }, [props.headerState]);

  return (
    <ui className={`header`} ref={headerRef}>
        {props.children}
    </ui>
  );
}

export default Header;
