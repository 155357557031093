
import { GiHamburgerMenu } from "react-icons/gi";
import "../styles/main.scss";

function BurgerMenu(props) {

  return (
    <div 
      className={"burgermenu"}
      onClick={props.changeHeader}
    >
      <GiHamburgerMenu 
        size={20}
        className={"icons"}
        color={"white"}
      />
    </div>
  );
}

export default BurgerMenu;
