import '../styles/main.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainPage from '../pages/MainPage';
import Grant from '../pages/Grant';
import Header from './Header';
import Footer from './Footer';
import LinkComponent from './Link';
import React, { useCallback, useEffect, useState } from 'react';
import BurgerMenu from './BurgerMenu';
import { Link } from 'react-scroll';
import { FiChevronUp } from 'react-icons/fi';

const Scroll = require('react-scroll');

function Main() {
  const [isBurgerMenu, setBurgerMenu] = useState(false);
  const [headerState, setHeaderState] = useState(false);
  const [section, setSection] = useState('');
  const [offset, setOffset] = useState(window.innerWidth < 769 ? -10 : -50);

  const changeHeader = () => {
    if (window.innerWidth < 769) {
      setHeaderState(!headerState);
    }
  };

  const activateSection = (type) => {
    setSection(type);
  };

  const onresize = useCallback(() => {
    setOffset(window.innerWidth < 769 ? -10 : -50);
  }, []);

  const scrollToTop = () => {
    const scroll = Scroll.animateScroll;
    scroll.scrollToTop({ smooth: true });
  };

  const gototop =
    section !== 'home' ? (
      <Link className={'gototop'} onClick={scrollToTop}>
        <FiChevronUp size={35} className={'icons'} />
      </Link>
    ) : null;

  useEffect(() => {
    window.addEventListener('resize', onresize);
    return () => {
      window.removeEventListener('resize', onresize);
    };
  }, [offset, onresize]);

  return (
    <BrowserRouter>
      <div className={'wrapper'}>
        <Switch>
          <Route exact path="/">
            <BurgerMenu
              isBurgerMenu={isBurgerMenu}
              changeHeader={changeHeader}
            />
            <Header
              setBurgerMenu={setBurgerMenu}
              headerState={headerState}
              changeHeader={changeHeader}
            >
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'home'}
                  target={'home'}
                  text={'Home'}
                  offset={offset}
                />
              </div>
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'about'}
                  target={'about'}
                  text={'About'}
                  offset={offset}
                />
              </div>
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'research'}
                  target={'research'}
                  text={'Research'}
                  offset={offset}
                />
              </div>
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'members'}
                  target={'members'}
                  text={'Group Members'}
                  offset={offset}
                />
              </div>
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'pubs'}
                  target={'pubs'}
                  text={'Publications / Highlights'}
                  offset={offset}
                />
              </div>
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'hobbies'}
                  target={'hobbies'}
                  text={'Hobbies'}
                  offset={offset}
                />
              </div>
              <div className={'header_link_container'}>
                <LinkComponent
                  changeHeader={changeHeader}
                  active={section === 'contact'}
                  target={'contact'}
                  text={'Contact'}
                  offset={offset}
                />
              </div>
            </Header>
            <MainPage activateSection={activateSection} />
          </Route>
          <Route path="/grant">
            <Grant />
          </Route>
        </Switch>
        {gototop}
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default Main;
