import "../images/ncn-inverted.svg";
import "../styles/main.scss";

function Footer() {

  return (
    <div className={"footer"}>
        <div className="logos">
          <a href={"http://www.pwr.edu.pl/en"}><div className={"universitylogo"}/></a>
          <a href={"http://www.kft.pwr.edu.pl/"}><div className={"dptlogo"}/></a>
          <a href={"https://ncn.gov.pl/"} target={"_blank"} rel="noreferrer"><div className={"ncnlogo"}/></a>
        </div>
        <div className={"designedby"}><a href={"mailto: michelmoretti83@gmail.com"}><p>Designed by:</p><p>Michael Moretti</p></a></div>
    </div>
  );
}

export default Footer;
