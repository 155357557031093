import { researchs } from "../data/researchs";

const Research = () => {

  const renderResearch = (res) => {
    return (
      <>
        <h3>{res.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: res.content }}/>
      </>
    );
  };

  return (
    <div>
      <h2>Research</h2>
      {researchs.map(renderResearch)}
    </div>
  );
};

export default Research;
