import { MdTravelExplore, MdDownhillSkiing } from 'react-icons/md';
import { FaChess } from 'react-icons/fa';
import { IoAirplaneSharp } from 'react-icons/io5';

const Hobbies = () => {
  return (
    <div style={{ width: '100%' }}>
      <h2>Hobbies</h2>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <MdTravelExplore size={22} />
        </span>
        <span className={'contact-line-text'}>Traveling</span>
      </p>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <MdDownhillSkiing size={22} />
        </span>
        <span className={'contact-line-text'}>Skiing</span>
      </p>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <IoAirplaneSharp size={22} />
        </span>
        <span className={'contact-line-text'}>Flying</span>
      </p>
      <p className={'contact-line'}>
        <span className={'contact-line-icon'}>
          <FaChess size={22} />
        </span>
        <span className={'contact-line-text'}>Chess</span>
      </p>
    </div>
  );
};

export default Hobbies;
