// eslint:disable
import { useCallback, useEffect, useRef } from 'react';
import { Element } from 'react-scroll';
import About from '../sections/about';
import Contact from '../sections/contact';
import Hobbies from '../sections/hobbies';
import Members from '../sections/members';
import Publications from '../sections/publications';
import Research from '../sections/research';
import '../styles/main.scss';

function MainPage(props) {
  const homeRef = useRef();
  const aboutRef = useRef();
  const publicationRef = useRef();
  const researchRef = useRef();
  const hobbiesRef = useRef();
  const membersRef = useRef();
  const contactRef = useRef();

  const onscroll = useCallback(() => {
    if (
      aboutRef?.current?.getBoundingClientRect()?.top - 84 > 0
    ) {
      props.activateSection("home");
    } else if (
      aboutRef?.current?.getBoundingClientRect()?.top - 84 < 0 &&
      researchRef?.current?.getBoundingClientRect()?.top - 84 > 0
    ) {
      props.activateSection("about");
    } else if (
      researchRef?.current?.getBoundingClientRect()?.top - 84 < 0 &&
      membersRef?.current?.getBoundingClientRect()?.top - 84 > 0
    ) {
      props.activateSection('research');
    } else if (
      membersRef?.current?.getBoundingClientRect()?.top - 84 < 0 &&
      publicationRef?.current?.getBoundingClientRect()?.top - 84 > 0
    ) {
      props.activateSection('members');
    } else if (
      publicationRef?.current?.getBoundingClientRect()?.top - 84 < 0 &&
      hobbiesRef?.current?.getBoundingClientRect()?.top - 84 > 0
    ) {
      props.activateSection('pubs');
    } else if (
      hobbiesRef?.current?.getBoundingClientRect()?.top - 84 < 0 &&
      contactRef?.current?.getBoundingClientRect()?.top - 84 > 0
    ) {
      props.activateSection('hobbies');
    } else if (contactRef?.current?.getBoundingClientRect()?.top - 84 < 0) {
      props.activateSection('contact');
    }
  }, [props]);

  useEffect(() => {
    window.addEventListener('scroll', onscroll);
    return () => {
      window.removeEventListener('scroll', onscroll);
    };
  }, [onscroll]);

  return (
    <div>
      <div className={'section__image__home'} />
      <div className={'section__wrapper'}>
        <Element
          className={'section section-home'}
          name={'home'}
          containerId={'home'}
        >
          <div ref={homeRef} style={{ position: 'relative', width: '100%' }}>
            <div className={'title-page'}>Piotr Surówka</div>
          </div>
        </Element>
        <Element className={'section'} name={'about'} containerId={'about'}>
          <div ref={aboutRef}>
            <About />
          </div>
        </Element>
        <Element
          className={'section'}
          name={'research'}
          containerId={'research'}
        >
          <div ref={researchRef}>
            <Research />
          </div>
        </Element>
        <Element className={'section'} name={'members'} containerId={'members'}>
          <div ref={membersRef}>
            <Members />
          </div>
        </Element>
        <Element className={'section'} name={'pubs'} containerId={'pubs'}>
          <div ref={publicationRef}>
            <Publications />
          </div>
        </Element>
        <Element className={'section'} name={'hobbies'} containerId={'hobbies'}>
          <div ref={hobbiesRef} style={{ width: '100%' }}>
            <Hobbies />
          </div>
        </Element>
        <Element
          className={'section'}
          name={'contact'}
          containerId={'contacts'}
        >
          <div ref={contactRef}>
            <Contact />
          </div>
        </Element>
      </div>
    </div>
  );
}

export default MainPage;
