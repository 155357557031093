import "./styles/main.scss";
import Main from './components/Main';

function App() {
  return (
    <Main/>
  );
}

export default App;
