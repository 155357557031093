import { Link } from 'react-scroll';
import '../styles/main.scss';
const Scroll = require('react-scroll');

function LinkComponent(props) {
  const onclickhandler = () => {
    props.changeHeader();
    if (props.target === 'home') {
      const scroll = Scroll.animateScroll;
      scroll.scrollToTop({ smooth: true });
    }
  };

  return (
    <Link
      to={props.target}
      onClick={onclickhandler}
      className={`header__link ${props.active ? "header__link__active" : ""}`}
      smooth={true}
      offset={props.offset}
    >
      {props.text ?? props.children}
    </Link>
  );
}

export default LinkComponent;
